/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useCallback, useEffect, useState } from "react"
import TableOptions from "common/TableOptions";
import { PATH } from "constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTableColumns } from "utils/tableUtils";
import { FlexBoxVertical } from "styles/styles";
const Companies = () => {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state?.CompanyModel);
  const [tableColumns, setTableColumns] = useState([]);
  const navigate = useNavigate()
  const [companyList, setCompanyList] = useState([])

  const getColumns = useCallback(() => {
    const columns = Admin?.companyList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  }
  );

  useEffect(() => {
    dispatch.CompanyModel.requestGetCompanyList();
    getColumns()
  }, [])

  useEffect(() => {
    if (companies?.length > 0) {
      setCompanyList(companies);
    }
  }, [companies]);
  return (
    <FlexBoxVertical>
      <TableOptions title="Companies" btnText="Add Company" path={PATH.AddCompany} />
      <CustomTable data={companyList} columns={tableColumns} onRowClick={(record) => {
        navigate(`${PATH.CompanyDetails}/${record?.id}`)
      }} />
    </FlexBoxVertical>
  )
}

export default Companies