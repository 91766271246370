/* eslint-disable import/no-unresolved */

import React from "react"
import { TableOptionWrapper } from "./styles"
import Typography from "common/Typography"
import Button from "common/Button"
import { useNavigate } from "react-router-dom"

const TableOptions = ({ title, path, btnText }) => {
  const navigate = useNavigate()
  return (
    <TableOptionWrapper>
      <Typography level={4}>{title}</Typography>
      <Button onClick={path ? () => navigate(path) : null} type="default">{btnText}</Button>
    </TableOptionWrapper>
  )
}

export default TableOptions