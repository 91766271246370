/* eslint-disable import/no-unresolved */

import CustomCard from "common/Card"
import React from "react";
import Jobs from "../../assets/Images/jobs.svg";
import Interviews from "../../assets/Images/interviews.svg"

import styled from "styled-components";
import { PATH } from "constants";

const GridContainer = styled.div`
     display:grid;
     grid-template-columns:auto auto auto auto;
     gap:20px;
`
const Dashboard = () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <GridContainer>
        <CustomCard src={Jobs} cardTitle={"500"} cardDescription={"No of Jobs Created"} path={PATH.Jobroles} width="100%" />
        <CustomCard src={Interviews} cardTitle={"350"} cardDescription={"Completed Interviews"} path={PATH.Interviews} width="100%" />
        <CustomCard src={Jobs} cardTitle={"180"} cardDescription={"Pending Interviews"} path={PATH.Interviews} width="100%" />
        <CustomCard src={Interviews} cardTitle={"340"} cardDescription={"Pending Interviews"} path={PATH.Interviews} width="100%" />

      </GridContainer>
    </div>
  )
}

export default Dashboard
