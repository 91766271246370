/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from "react";
import { Table } from "antd";

const CustomTable = ({ columns, data, onRowClick, selectable, onRowSelect
}) => {
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      if (onRowSelect) {
        onRowSelect(selectedRows)
      }
    },
  };
  return (
    <Table
      columns={columns}
      dataSource={data}
      onRow={(record) => ({
        onClick: () => {
          if (onRowClick) {
            onRowClick(record);
          }
        },
      })}
      pagination={{
        pageSize: 10,
      }}
      scroll={{
        y: "60vh",
      }}
      rowSelection={
        selectable
          ? {
            type: "checkbox",
            ...rowSelection,
          }
          : false
      }
      rowKey="id"
    />
  )
}
export default CustomTable;