/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useCallback, useEffect, useState } from "react"
import TableOptions from "common/TableOptions";
import { PATH } from "constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTableColumns } from "utils/tableUtils";
import { FlexBoxVertical } from "styles/styles";
const Candidates = () => {
  const dispatch = useDispatch();
  const {candidates} = useSelector((state) => state?.CandidateModel);
  const [tableColumns, setTableColumns] = useState([]);
  const [candidateList, setCandidateList] = useState([])

  const navigate = useNavigate();
  
  const getColumns = useCallback(() => {
    const columns = Admin?.candidateList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  }
  );
 
  useEffect(() => {
    dispatch.CandidateModel.requestGetCandidateList();
    getColumns()
  }, [])

  useEffect(() => {
    if (candidates?.length > 0) {
      setCandidateList(candidates);
    }
  }, [candidates]);

  return (
    <FlexBoxVertical>
      <TableOptions title="Candidates" btnText="Add Candidate" path="/add-candidate" />
      <CustomTable data={candidateList} columns={tableColumns}/>
    </FlexBoxVertical>
  )
}

export default Candidates