/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { PATH } from "constants";
import { useNavigate } from "react-router-dom";
import { getItemToLocalStorage } from "utils"
import { useDispatch } from "react-redux";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    const { email, password } = values;
    try {
      const payload = { email: email, password: password }
      await dispatch?.LoginModel?.requestPostLogin(payload);
      const updatedUserData = getItemToLocalStorage("data");
      if (updatedUserData.token) {
        navigate(PATH.Dashboard);
        window.location.reload();
      }
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <div>
      <Form
        name="normal_login"
        style={{ width: "40vw" }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        size='large'
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your Email!",
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="/forget-password">
            Forgot password
          </a>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Log in
          </Button>
          {/* Or <a href="">register now!</a> */}
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;