/* eslint-disable import/no-unresolved */
import CommonLayout from "components/CommonLayout"
import React from "react"

const { Navigate } = require("react-router-dom")

const PrivateRoutes = () => {
  let auth = { "token": true }
  return (
    auth.token ? <CommonLayout /> : <Navigate to='/login' />
  )
}

export default PrivateRoutes