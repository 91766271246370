/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */

import React, { useState } from "react";
import { Dropdown, Space, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import { LogoutOutlined, SmileTwoTone, UserOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { PATH } from "constants";
import { getCapitalizeFirstLetter, getItemToLocalStorage, removeItemFromLocalStorage } from "utils";
import Modal from "common/Modal";
const Navbar = () => {
  const navigate = useNavigate();
  const [openLogoutModal,setOpenLogoutModal] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    setOpenLogoutModal(!openLogoutModal)
  }

  const handleSubmitLogout = () =>{
    removeItemFromLocalStorage();
    navigate(PATH.Login)
  }

  const handleCancelLogout = () =>{
    setOpenLogoutModal(!openLogoutModal);
  }
  const handleProfile = () => {
    navigate(PATH.Profile)
  }
  const userData = getItemToLocalStorage("data");

  const items = [
    {
      key: "1",
      label: (
        <div role="button"
          tabIndex={0}
          href={PATH.Profile} style={{ fontSize: "17px" }} onClick={handleProfile}>
          Profile
        </div>
      ),
      icon: <SmileTwoTone />,
    },
    {
      key: "4",
      danger: true,
      label: (
        <div style={{ fontSize: "17px" }} onClick={handleLogout} role="button"
          tabIndex={0}
          aria-label="Logout">
          Logout
        </div>
      ),
      icon: <LogoutOutlined />
    },
  ];
  return (
    <>
      <Header
        style={{
          padding: "10px",
          background: colorBgContainer,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: "17px", marginRight: "20px" }}>
            {getCapitalizeFirstLetter(`${userData?.profile?.first_name} ${userData?.profile?.last_name}`)}
          </span>
        </div>

        <Dropdown
          menu={{
            items,
          }}
        >
          <div style={{ cursor: "pointer" }}>
            <Space size="large" style={{ paddingRight: "20px" }}>
              <UserOutlined style={{ fontSize: "25px" }} />
            </Space>
          </div>
        </Dropdown>
      </Header>
      {openLogoutModal &&
       <Modal title="Are you sure want to Logout?" okText="Logout" cancelText="Cancel" open={openLogoutModal} handleSubmit={handleSubmitLogout} onCancel={handleCancelLogout} centered={true} closeIcon={true} width={500}/>
      }
    </>
  )
}

export default Navbar
