/* eslint-disable import/no-unresolved */
import React from "react";
import { Button, Form, Input, Space, Row, Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";

const MultipleCandidatesForm = ({
  candidates,
  onAddCandidate,
  onRemoveCandidate,
  onSaveCandidates
}) => {
  const onFinish = (values) => {
    onSaveCandidates(values.candidates);
  };

  return (
    <Form
      name="multipleCandidatesForm"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ candidates }}
    >
      {candidates?.map((candidate, index) => (
        <Row key={index} gutter={8}>
          <Col span={6}>
            <Form.Item
              name={["candidates", index, "first_name"]}
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["candidates", index, "last_name"]}
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={["candidates", index, "email"]}
              rules={[
                { type: "email", message: "Please enter a valid email" },
                { required: true, message: "Please enter email" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          {index > 0 && (
            <Col span={6}>
              <Button
                type="default"
                onClick={() => onRemoveCandidate(index)}
              >
                <DeleteOutlined />  Remove
              </Button>
            </Col>
          )}
        </Row>
      ))}
      <Form.Item>
        <Space>
          <Button type="primary" onClick={onAddCandidate}>
            <PlusCircleOutlined /> Add Another Candidate
          </Button>
          <Button type="primary" htmlType="submit">
            <SaveOutlined /> Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default MultipleCandidatesForm;
