import React from "react"
import { Footer } from "antd/es/layout/layout"

const CustomFooter = () => {
  return (
    <>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
                Talent Berry Admin ©2023 by Telent Berry
      </Footer>
    </>
  )
}

export default CustomFooter
