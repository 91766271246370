import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
`;

export const FlexBoxVertical = styled(FlexBox)`
  display:flex;
  flex-direction:column;
`;
export const FlexBoxVerticalCenter = styled.div`
  display:flex;
  align-items: center;
  width : ${({ width }) => width ? width : "auto"}
`;

export const FlexBoxHorizontalCenter = styled(FlexBox)`
  justify-content: center;
`;

export const FlexBoxCentered = styled(FlexBox)`
  align-items: center;
  justify-content: center;
`;

export const CenteredBox = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const FlexBoxAlignRight = styled(FlexBox)`
  margin-left: auto;
`;

export const FullScreen = styled.div`
  height: 100vh;
  width: 100vw;
`;
export const FullScreenCentered = styled(FullScreen)`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const StatusBadge = styled.div`
  color: ${(props) => props.color};
  background-color: ${(props) => props.color};
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

export const ConditionalFlexBoxCentered = styled.div`
  display: ${(props) => (props?.center ? "flex" : "table-cell")};
  align-items: center;
  justify-content: center;
`;


export const ActiveStatusCheckBoxWrapper = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
`;

export const ActiveStatusCheckBox = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) => (props.status ? "green" : "white")};
`;