export const Admin = {
  interviewList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 50,
      },
      {
        dataIndex: "candidate_name",
        title: "Candidate Name",
        width: 150
      },
      {
        dataIndex: "job_title",
        title: "Job Title",
        width: 150
      },
      // {
      //   dataIndex: "passcode",
      //   title: "Passcode"
      // },
      {
        dataIndex: "interview_status",
        title: "Status",
        interviewStatus: true,
      },
      {
        dataIndex: "interview_report",
        title: "Download Response",
        download: true,
      },
      {
        dataIndex: "interview_link",
        title: "Interview Link",
        width: 200,
        copy: true
      },
      {
        dataIndex: "generate_response",
        title: "Generate Response",
        action: true,
      },
      {
        dataIndex: "upload_report",
        title: "Upload Report",
        action: true,
      },
    ]
  },
  interviewResponseList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "question",
        title: "Q-Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "question_text",
        title: "Question",
        width: 200
      },
      {
        dataIndex: "response",
        title: "Response Captured"
      },
      {
        dataIndex: "ai_response",
        title: "Transcript by AI",
      },
      {
        dataIndex: "response_record_link",
        title: "Response Recording Link",
        width: 400,
        audio: true,
      }
    ]
  },
  jobRoleList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "title",
        title: "Title",
        width: 250
      },
      {
        dataIndex: "job_description",
        title: "Description",
        width: 350
      },
      {
        dataIndex: "job_questions",
        title: "No of Questions",
        width: 150,
        align: "center",
        count: true,
      },
      {
        dataIndex: "is_active",
        title: "Status",
        align: "center",
        statusTag: true
      },
    ]
  },
  companyList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "company_name",
        title: "Company Name",
      },
      {
        dataIndex: "industry",
        title: "Industry",
        align: "center",
      },
      {
        dataIndex: "website",
        title: "Website",
        width: 250,
      },
      {
        dataIndex: "address",// combine the address_fileds1,address_fileds2,address_fileds3,city
        title: "Address",
        width: 250,
        combinedAddress: true
      },
      {
        dataIndex: "country",
        title: "Country",
      },
      {
        dataIndex: "is_active",
        title: "Status",
        // activeStatus: true,
        statusTag: true
      },
      // {
      //   dataIndex:"action",
      //   title:"Actions",
      //   width:300,
      //   actions: true,
      // }
    ]
  },
  candidateList: {
    selectable: true,
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "first_name",
        title: "First Name",
        width: 250,
      },
      {
        dataIndex: "last_name",
        title: "Last Name",
        width: 250
      },
      {
        dataIndex: "email",
        title: "Email",
        width: 250
      },
      {
        dataIndex: "active_status",
        title: "Status",
        statusTag: true
      }
    ]
  },
  questionList: {
    list: [
      {
        dataIndex: "id",
        title: "Id",
        sort: true,
        width: 100,
      },
      {
        dataIndex: "sequence",
        title: "Sequence",
        width: 150
      },
      {
        dataIndex: "question",
        title: "Question",
        width: 400
      },
      {
        dataIndex: "is_verified",
        title: "Status",
        align: "center",
        statusTag: true
      },
      {
        dataIndex: "action",
        title: "Action",
        width: 300
      }
    ]
  },
}