/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import CommonLayout from "components/CommonLayout";
import { PATH } from "constants";
import AddInterview from "pages/AddInterview";
import AddJob from "pages/AddJob";
import Dashboard from "pages/Dashboard";
import Home from "pages/Home";
import Interviews from "pages/Interviews";
import InterviewDetails from "pages/InterviewDetails";
import JobDetails from "pages/JobDetails";
import JobRoles from "pages/JobRoles";
import Auth from "pages/Login";
import PageNotFound from "pages/PageNotFound";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getItemToLocalStorage } from "utils";
import Employees from "pages/Employees";
import EmployeeDetails from "pages/EmployeeDetails";
import Companies from "pages/Companies";
import CompanyDetails from "pages/Companies/CompanyDetails";
import AddCompany from "pages/Companies/AddCompany";
import PrivateRoutes from "./privateRoute";
import Candidates from "pages/Candidates";
import CreateCandidate from "pages/CreateCandidate";
import Profile from "pages/Profile";

function AppRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const data = getItemToLocalStorage("data");

  const handleLogin = () => {
    setIsAuthenticated(true);
    alert("Logged In Successfully")
  };

  useEffect(() => {
    const data = getItemToLocalStorage();
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route  element={<PrivateRoutes />}>
            <Route index element={<Home />} />
            <Route path={PATH.Dashboard} element={<Dashboard />} />
            <Route path={PATH.Interviews} element={<Interviews />} />
            <Route path={PATH.Addjob} element={<AddJob />} />
            <Route path={PATH.Jobroles} element={<JobRoles />} />
            <Route path={`${PATH.JobDetails}/:id`} element={<JobDetails />} />
            <Route path={PATH.AddInterview} element={<AddInterview />} />
            <Route path={`${PATH.InterviewDetails}/:id`} element={<InterviewDetails />} />
            <Route path={PATH.Employees} element={<Employees />} />
            <Route path={PATH.EmployeeDetails} element={<EmployeeDetails />} />
            <Route path={PATH.Companies} element={<Companies />} />
            <Route path={`${PATH.CompanyDetails}/:id`} element={<CompanyDetails />} />
            <Route path={PATH.AddCompany} element={<AddCompany />} />
            <Route path={PATH.Candidates} element={<Candidates />} />
            <Route path={PATH.AddCandidate} element={<CreateCandidate />} />
            <Route path={PATH.Profile} element={<Profile />} />
          </Route>
          <Route path={PATH.Login} element={<Auth handleLogin={handleLogin} />} />
          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default AppRoutes;
