/* eslint-disable import/no-unresolved */
import React from "react";
import { Badge, Button, Tooltip, Typography } from "antd";
import TagComponent from "common/Tag";
import { ActiveStatusCheckBox, ActiveStatusCheckBoxWrapper, ConditionalFlexBoxCentered, FlexBoxVerticalCenter, StatusBadge } from "styles/styles";
import { getCapitalizeFirstLetter, getStatusColor } from "utils";
import { CheckCircleOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";

const { Paragraph } = Typography
export const createTableColumns = ({ columns }) => {
  if (columns) {
    const newColumns = columns.map((c) => {
      const title = c?.title;
      const dataIndex = c?.dataIndex;
      const width = c?.width || "auto";
      const align = c?.align;
      const isCenter = align === "center";
      const isStatus = c?.activeStatus;
      const isCheckbox = c?.activeCheckbox;
      const isSort = c?.sort;
      const isStatusTag = c?.statusTag;
      const isCombinedAddress = c?.combinedAddress;
      const isDownload = c?.download;
      const isCount = c?.count;
      const isInterviewStatus = c?.interviewStatus;
      const isAction = c?.action;
      const isAudio = c?.audio;
      const isCopyable = c?.copy;

      let obj = {
        title,
        dataIndex,
        width,
        align: align || "left",
        key: title,
        sorter: isSort ? (a, b) => a[c?.dataIndex] - b[c?.dataIndex] : false,
        render: (_, record) => {
          let comp = record[c?.dataIndex];
          if (isStatus) {
            comp = getStatusComponent(record, c?.dataIndex, isCenter);
          }
          if (isCheckbox) {
            comp = getCheckboxComponent(record, c?.dataIndex, isCenter);
          }
          if (isStatusTag) {
            comp = renderStatusTag(record, c?.dataIndex, isCenter);
          }
          if (isCombinedAddress) {
            comp = combineAddressFields(record, c?.dataIndex, isCenter);
          }
          if (isDownload) {
            comp = renderDownload(record[c?.dataIndex])
          }
          if (isCount) {
            comp = getCount(record[c?.dataIndex])
          }
          if (isInterviewStatus) {
            comp = renderInterviewStatus(record, dataIndex, isCenter);
          }
          if (isAction) {
            comp = renderAction(record, dataIndex);
          }
          if (isAudio) {
            comp = renderAudio(record, dataIndex)
          }
          if (isCopyable) {
            comp = renderTextCopy(record, dataIndex)
          }
          return comp;
        },
      };
      return obj;
    });
    return newColumns;
  }
};


const getStatusComponent = (record, key, isCenter) => {
  const statusColor = record[key] ? "#359E4D" : "#E72212";
  const isCustomTooltip = record[`${key}__tooltip`];
  const finalTooltip = isCustomTooltip || "";
  return (
    <Tooltip title={finalTooltip}>
      <ConditionalFlexBoxCentered center={isCenter}>
        <StatusBadge color={statusColor} />
      </ConditionalFlexBoxCentered>
    </Tooltip>
  );
};


const getCheckboxComponent = (record, key, isCenter) => {
  const isVerified = record[key];
  const onClick = record["onClick"];
  return (
    <ConditionalFlexBoxCentered center={isCenter}>
      <ActiveStatusCheckBoxWrapper
        isClickable={onClick}
        onClick={() => onClick && onClick(record)}
      >
        <ActiveStatusCheckBox status={isVerified} />
      </ActiveStatusCheckBoxWrapper>
    </ConditionalFlexBoxCentered>
  );
};

const renderStatusTag = (record, dataIndex) => {
  const { is_verified } = record;
  const possibleStatus = ["is_active", "active_status"]
  let statusColor;
  let statusLabel;
  if (dataIndex == "is_verified") {
    statusColor = getStatusColor(is_verified);
    statusLabel = is_verified ? "Verified" : "UnVerified"
  }
  if (possibleStatus.includes(dataIndex)) {
    statusColor = getStatusColor(record[dataIndex]);
    statusLabel = record[dataIndex] ? "Active" : "InActive"
  }
  return (
    <>
      <TagComponent
        bordered={false}
        color={statusColor}
        label={getCapitalizeFirstLetter(statusLabel)}
      />
    </>
  );
};

const combineAddressFields = (record) => {
  const addressFields = [
    record["address_field1"],
    record["address_field2"],
    record["address_field3"],
    record["city"],
  ].filter(Boolean);
  return addressFields.join(", ");
};


const renderDownload = (downloadUrl) => {
  const handleDownloadClick = (e) => {
    e.stopPropagation();
  };

  if (downloadUrl) {
    return (
      <a href={downloadUrl} download onClick={handleDownloadClick}>
        <Tooltip title="Download File">
          <FlexBoxVerticalCenter style={{ gap: "10px" }}>
            <DownloadOutlined />
            <span>Download</span>
          </FlexBoxVerticalCenter>
        </Tooltip>
      </a>
    );
  }

  return (
    <Badge
      status="processing"
      size="medium"
      style={{ fontSize: ".6rem", backgroundColor: "rgb(209 213 219)", color: "black" }}
      count="N/A"
    />
  );
};

const getCount = (data) => {
  return data.length
}


const getInterviewStatusColor = (status) => {
  switch (status.toLowerCase()) {
  case "initiated":
    return "#1890ff"; // Blue color
  case "ongoing":
    return "#2db7f5"; // Light Blue color
  case "completed":
    return "#87d068"; // Green color
  case "report initiated":
    return "#fadb14"; // Yellow color
  case "report generated":
    return "#108ee9"; // Dark Blue color
  default:
    return "#d9d9d9"; // Default color
  }
};

const renderInterviewStatus = (record, key) => {
  const status = record[key];
  const color = getInterviewStatusColor(status);

  return (
    <>
      <TagComponent
        bordered={false}
        color={color}
        label={getCapitalizeFirstLetter(status)}
      />
    </>
  );
};

const renderAction = (record, dataIndex) => {
  let buttonText;
  let actionFunction;

  if (dataIndex === "upload_report") {
    buttonText = "Upload"
    actionFunction = record.uploadReport
  } else if (dataIndex === "generate_response") {
    buttonText = "Generate"
    actionFunction = record.generateResponse
  } else {
    buttonText = "Action"
    actionFunction = null;
  }
  const handleClick = (e) => {
    e.stopPropagation();
    if (actionFunction) {
      actionFunction(record);
    }
  }
  return (
    <Button size="small" style={{ cursor: "pointer" }} onClick={handleClick}>
      {buttonText}
    </Button>
  )
}

const renderAudio = (record, dataIndex) => {
  const audioUrl = record[dataIndex];
  return (
    <div>
      <audio controls src={audioUrl}>
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};


const renderTextCopy = (record, dataIndex) => {
  const dataToCopy = record[dataIndex]
  return (
    <Paragraph
      copyable={{
        text: dataToCopy,
        icon: [<CopyOutlined key="copy-icon" />, <CheckCircleOutlined key="copied-icon" style={{ color: "green" }} />],
        tooltips: ["Copy Interview link.", "Copied Interview link!"],
      }}
      style={{ display: "inline" }}
    >
      Copy Interview Link
    </Paragraph>
  )
}