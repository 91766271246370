/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { useDispatch } from "react-redux";
import { FlexBox } from "styles/styles";
import AvatarUpload from "common/AvatarUpload";

const AddCompanyForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = useState(false);
  const [logoFile,setLogoFile] = useState()

  const values = Form.useWatch([], form);

  const handleLogoChange = (uploadedData) => {
    setLogoFile(uploadedData)
  };

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  const handleSubmit = async () => {
    try {
      const keys = Object.keys(values);
      const formData = new FormData();
      for (let key of keys) {
        formData.append(key, values[key])
      }
      formData.append("logo", logoFile)
      await dispatch?.CompanyModel?.requestCreateCompany(formData);
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <Form form={form} name="addCompany" layout="vertical" autoComplete="off" onFinish={handleSubmit}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="company_name"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter the company name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="industry"
            label="Industry"
            rules={[
              {
                required: true,
                message: "Please enter the industry",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="website"
            label="Website Link"
            rules={[
              {
                required: true,
                message: "Please enter the website link",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {/* <Col span={4}>
          <Form.Item
            name="is_active"
            label="Is Active"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="address_field1"
            label="Address Line 1"
            rules={[
              {
                required: true,
                message: "Please enter address line 1",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address_field2"
            label="Address Line 2"
            rules={[
              {
                required: true,
                message: "Please enter address line 2",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="address_field3"
            label="Address Line 3"
            rules={[
              {
                required: true,
                message: "Please enter address line 3",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "Please enter the state",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "Please enter the city",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please enter the country",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>


        <Col span={8}>
          <Form.Item
            name="logo"
            label="Company Logo"
            rules={[
              {
                required: true,
                message: "Please upload the company logo",
              },
            ]}
          >
            <AvatarUpload handleChange={handleLogoChange} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="pincode"
            label="Pincode"
            rules={[
              {
                required: true,
                message: "Please enter the pincode",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <FlexBox style={{ justifyContent: "flex-end" }}>
        <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleSubmit}>
          Create Company
        </Button>
      </FlexBox>
    </Form>
  );
};

export default AddCompanyForm;



// 