import React from "react";
import { Input } from "antd";

const { TextArea: AntTextArea } = Input;

const TextArea = (props) => {
  const { rows = 2, onChange, value } = props;

  return (
    <>
      <AntTextArea rows={rows} onChange={onChange} value={value || ""} />
    </>
  );
};

export default TextArea;
