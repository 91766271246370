/* eslint-disable import/no-unresolved */

import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useCallback, useEffect, useState } from "react"
import { createTableColumns } from "utils/tableUtils";

const CandidateResponseList = ({candidateResponse}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [responseList, setResponseList] = useState([])

  const getColumns = useCallback(() => {
    const columns = Admin?.interviewResponseList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  }
  );

  useEffect(() => {
    getColumns()
  }, [])

  useEffect(() => {
    if (candidateResponse?.length > 0) {
      setResponseList(candidateResponse);
    }
  }, [candidateResponse]);

  return (
    <div>
      <CustomTable
        columns={tableColumns}
        data={responseList}
      />
    </div>
  )
}

export default CandidateResponseList