/* eslint-disable import/no-unresolved */

import React, { useCallback, useEffect, useState } from "react"
import DefaultQuestion from "./default-question"
import CustomTable from "components/CustomTable"
import { Admin } from "constants/Tables";
import { createTableColumns } from "utils/tableUtils";
import { useDispatch, useSelector } from "react-redux";

const ListQuestion = ({ jobId }) => {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state?.QuestionModel);
  const [tableColumns, setTableColumns] = useState([]);
  const [questionList, setQuestionList] = useState([])
  const getColumns = useCallback(() => {
    const columns = Admin?.questionList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  }
  );

  useEffect(() => {
    dispatch.QuestionModel.requestGetQuestionList(jobId);
    getColumns()
  }, [jobId])

  useEffect(() => {
    if (questions.length > 0) {
      setQuestionList(questions);
    }
  }, [questions]);
  return (
    <div>
      {questions?.length ?
        <CustomTable data={questionList} columns={tableColumns} onRowClick={() => {
          // navigate(PATH.QuestionDetails)
        }} />
        :
        <DefaultQuestion />
      }
    </div>
  )
}

export default ListQuestion