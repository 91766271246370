/* eslint-disable import/no-unresolved */
import React from "react"
import { Typography } from "antd"
import AddCompanyForm from "components/AddCompanyForm"

const { Title } = Typography
const AddCompany = () => {
  return (
    <div style={{ padding: "15px", borderRadius: "4px", background: "white", border: "1px solid lightgray" }}>
      <Title level={4}>Add Company</Title>
      <AddCompanyForm />
    </div>
  )
}

export default AddCompany
