import styled from "styled-components";
import { Typography as AntTypography } from "antd";
const { Title } = AntTypography;

const DoubleExtraLarge = `
    font-size:3rem !important;
    font-weight:700 !important;
    margin:0 !important;
`;

const ExtraLarge = `
    font-size:2.8rem !important;
    font-weight:600 !important;
    margin:0 !important;
`;

const Large = `
    font-size:2.125 !important;
    font-weight:700 !important;
    margin:0 !important;
`;

const Medium = `
    font-size:1.698 !important;
    line-height: 4rem !important;
    font-weight:700 !important;
    margin:0 !important;
`;
const Small = `
    font-size:1.125 !important;
    line-height: 2.125rem !important;
    font-weight:700 !important;
    margin:0 !important;
`;

const ExtraSmall = `
    font-size:0.875rem !important;
    line-height: 1.25rem !important;
    font-weight:400 !important;
    margin:0 !important;
`;

const renderTypography = {
  1: DoubleExtraLarge,
  2: ExtraLarge,
  3: Large,
  4: Medium,
  5: Small,
  6: ExtraSmall,
};

export const StyledTitle = styled(Title)`
  ${(props) => renderTypography[props.level]}
`;
