/* eslint-disable import/no-unresolved */
import { Upload } from "antd";
import React, { useState } from "react";

const AvatarUpload = ({ handleChange }) => {
  const [file, setFile] = useState(null);

  const customRequest = ({ file }) => {
    setFile(file);
  };

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-circle"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={customRequest}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            background: "#f0f0f0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Upload
        </div>
      </Upload>
      {file && handleChange && handleChange(file)}
    </>
  );
};

export default AvatarUpload;
