/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import { LIST_INTERVIEW_URL, CREATE_INTERVIEW_URL, INTERVIEW_DETAILS_URL, INTERVIEW_REPORT_GENERATION, INTERVIEW_REPORT_UPLOAD } from "constants/api";

const model = {
  state: {
    interviews: [],
    loading: false,
    error: "",
    interviewDetails: {},
  },
  reducers: {
    setInterviews: (state, payload) => {
      return {
        ...state,
        interviews: payload,
      };
    },
    setInterviewDetails: (state, payload) => {
      return {
        ...state,
        interviewDetails: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestGetInterviewList() {
      try {
        this.setLoadingState(true);
        const response = await api.get(LIST_INTERVIEW_URL);
        if (response) {
          this.setLoadingState(false);
          this.setInterviews(response);
        }
        return response
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data
      }
    },
    async requestCreateInterview(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(CREATE_INTERVIEW_URL, payload);
        if (response)
          return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data
      }
    },
    async requestGetInterviewDetails(interviewId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${INTERVIEW_DETAILS_URL}/${interviewId}`);
        if (response) {
          this.setInterviewDetails(response)
          this.setLoadingState(false);
        }
        return response
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data
      }
    },
    async generateInterviewReport(interviewId) {
      try {
        this.setLoadingState(true);
        const response = await api.get(`${INTERVIEW_REPORT_GENERATION}/${interviewId}`);
        if (response) {
          this.setLoadingState(false);
          this.requestGetInterviewList();
        }
        return response
      } catch (err) {
        this.setLoadingState(false);
        this.setErrorState(err?.response?.data?.message);
        return err?.response?.data
      }
    },
    async requestUploadReport(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(INTERVIEW_REPORT_UPLOAD, payload);
        if (response)
          return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data
      }
    },
  }),
};
export default model;
