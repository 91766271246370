import axios from "axios";
import { getItemToLocalStorage, removeItemFromLocalStorage } from "../utils/index";
// import store from "redux/store";

const user = getItemToLocalStorage("data");
const axiosInstance = axios.create();
let userAuth = {};
if (user && user.token) {
  userAuth = { headers: { Authorization: `Token ${user.token}` } };
}

const options = {
  // withCredentials: true,
  ...userAuth, // add other options here
};

axiosInstance.interceptors.request.use(config => {

  // store.dispatch.spinLoading.setLoading(true);
  return config;
}, error => {
  // store.dispatch.spinLoading.setLoading(false);
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  response => {
    // store.dispatch.spinLoading.setLoading(false);
    return response;
  },
  error => {
    // store.dispatch.spinLoading.setLoading(false);
    if (error.response.status === 403 && error.response.data.message === "Invalid Token") {
      removeItemFromLocalStorage();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const axiosGet = async (url) => {
  const res = await axiosInstance.get(url, options);
  return res?.data;
};

const axiosPost = async (url, payload) => {
  const res = await axiosInstance.post(url, payload, options);
  return res?.data;
};

const axiosPut = async (url, payload) => {
  const res = await axiosInstance.put(url, payload, options);
  return res?.data;
};

const axiosPatch = async (url, payload) => {
  const res = await axiosInstance.patch(url, payload, { ...options });
  return res?.data;
};

const axiosDelete = async (url, payload) => {
  const res = await axiosInstance.post(url, payload, options);
  return res?.data;
};

export const api = {
  get: axiosGet,
  post: axiosPost,
  put: axiosPut,
  delete: axiosDelete,
  patch: axiosPatch
};
