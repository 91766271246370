/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import CustomTable from "components/CustomTable";
import { Admin } from "constants/Tables";
import React, { useCallback, useEffect, useState } from "react"
import TableOptions from "common/TableOptions";
import { PATH } from "constants";
import { useNavigate } from "react-router-dom";
const Employees = () => {
  const [tableColumns, setTableColumns] = useState([]);
  const navigate = useNavigate()
  const getColumns = useCallback(() => {
    const columns = Admin?.employeeList?.list;
    setTableColumns(columns);
  }
  );

  const employeeList = [
    {
      id: "1",
      employee_name: "Akash R P",
      role: "Software Engineer",
      email:"akash@gmail.com",
      questions_attempted: "10",
    },
    {
      id: "2",
      employee_name: "Vinayak M",
      role: "Product Manager",
      email:"vinayak@gmail.com",
      questions_attempted: "8",
    },
    {
      id: "3",
      employee_name: "Test ",
      role: "Marketing Manager",
      email:"test@gmail.com",
      questions_attempted: "15",
    },
  ]
  useEffect(() => {
    getColumns()
  }, [])

  return (
    <>
      <TableOptions title="Employees" btnText="AddEmployee" path={PATH.AddEmployee} />
      <CustomTable data={employeeList} columns={tableColumns} onRowClick={(record) => {
        navigate(PATH.EmployeeDetails)
      }} />
    </>
  )
}

export default Employees