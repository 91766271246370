/* eslint-disable import/no-unresolved */
import { Card } from "antd"
import Image from "common/Image";
import React from "react"
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Meta } = Card;

const StyledCard = styled(Card)`
  && .ant-card-meta-title {
    white-space: unset;
  }
`;


const CustomCard = (props) => {
  const navigate = useNavigate();

  const { width = 240, alt = "card image", src, cardTitle, cardDescription, size, path, imgStyle = { marginTop: "15px", height: "50px" }, onClick } = props;
  const handleCardClick = (event) => {
    if (path) {
      navigate(path);
    }

    if (onClick && typeof onClick === "function") {
      onClick(event);
    }
  };
  return (
    <>
      <StyledCard
        hoverable
        style={{
          width: width,
          border: "1px solid #d6cccc",
        }}
        size={size}

        cover={src && <Image style={imgStyle} alt={alt} src={src} />}
        onClick={handleCardClick}
      >
        <Meta title={cardTitle} description={cardDescription} style={{ textAlign: "center" }} />
      </StyledCard>
    </>
  )
}

export default CustomCard
