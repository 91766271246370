import React from "react";
import { Input as AntInput } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Wrapper = styled.div`
  .bg-color Input {
    color: #4f555a;
    font-size: 0.988rem;
    font-weight: 500;
  }
`;

const Input = (props) => {
  const {
    placeholder = "name",
    type = "text",
    onChange,
    value,
    bordered = true,
  } = props;
  const applyStyle = {
    color: "#4f555a",
    fontSize: "0.988rem",
    fontWeight: 500,
    border: bordered ? "1px solid rgba(0, 0, 0, 0.1)" : "",
    borderRadius: "5px",
    padding: "8px 10px",
  };

  return (
    <Wrapper>
      {type === "password" ? (
        <AntInput.Password
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          style={applyStyle}
          className="bg-color"
          {...props}
        />
      ) : (
        <AntInput
          style={applyStyle}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          bordered={false}
          className="bg-color"
          {...props}
        />
      )}
    </Wrapper>
  );
};

Input.defaultProps = {
  bordered: true
}

export default Input;
