/* eslint-disable import/no-unresolved */
/* eslint-disable react/display-name */

import React from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import CustomFooter from "layout/Footer";
import Navbar from "layout/Header";
import SideBar from "layout/Sider";
import { Outlet } from "react-router-dom";

const CommonLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar />
      <Layout>
        <Navbar />
        <Content style={{ margin: "0 16px" }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
        <CustomFooter />
      </Layout>
    </Layout>
  );
};

export default CommonLayout;
