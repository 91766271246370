import { init } from "@rematch/core";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";
import LoginModel from "./models/Login"
import InterviewModel from "./models/InterviewList";
import JobModel from "./models/Jobs";
import CompanyModel from "./models/Companies";
import CandidateModel from "./models/Candidates";
import QuestionModel from "./models/Questions"
const persistConfig = {
  key: "root",
  storage,
};


const store = init({
  plugins: [persistPlugin(persistConfig)],
  models: {
    LoginModel,
    InterviewModel,
    JobModel,
    CompanyModel,
    CandidateModel,
    QuestionModel
  }
});

export default store;