/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { Button, Card, Divider, Space, Typography } from "antd";
import FileUpload from "common/FileUpload";
import MultipleCandidatesForm from "components/MultipleCandidateForm";
import { PATH } from "constants";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlexBox } from "styles/styles";

const { Title, Text } = Typography;

const CreateCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [candidates, setCandidates] = useState([
    { first_name: "", last_name: "", email: "" },
  ]);
  const [excelFile, setExcelFile] = useState();
  const [isFileUploaded,setIsFileUploaded] = useState(false)

  const handleAddCandidate = () => {
    setCandidates((prevCandidates) => [
      ...prevCandidates,
      { first_name: "", last_name: "", email: "" },
    ]);
  };


  const handleRemoveCandidate = (indexToRemove) => {
    setCandidates((prevCandidates) =>
      prevCandidates.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSaveCandidates = (values) => {
    try {
      dispatch?.CandidateModel?.requestCreateCandidate(values);
      navigate(PATH.Candidates);
    } catch (error) {
      console.log(error)
    }
  };

  const handleFileUpload = (data) => {
    setIsFileUploaded(!isFileUploaded)
    setExcelFile(data);
  };

  const handleSubmitFile = () => {
    const formData = new FormData();
    formData.append("candidate_file", excelFile);
    dispatch.CandidateModel.requestCandidateBulkCreate(formData);
    setExcelFile(null)
    setIsFileUploaded(!isFileUploaded)
  };

  return (
    <>
      <Card>
        <FlexBox style={{ flexDirection: "column", gap: "10px" }}>
          <Title level={4}>Create Multiple Candidate</Title>
          <MultipleCandidatesForm
            candidates={candidates}
            onAddCandidate={handleAddCandidate}
            onRemoveCandidate={handleRemoveCandidate}
            onSaveCandidates={handleSaveCandidates}
          />
        </FlexBox>
      </Card>
      <Divider>OR</Divider>
      <Card>
        <FlexBox style={{ flexDirection: "column", gap: "20px" }}>
          <Title level={3}>Bulk Creation with Excel</Title>
          <Text>
            Upload a Excel file containing candidate information for bulk creation.
          </Text>
          <FileUpload onFileUpload={handleFileUpload} />
          <Divider />
          <div style={{ marginTop: "10px" }}>
            <Button disabled={!isFileUploaded} type="primary" onClick={handleSubmitFile}>
              Create Bulk Candidates
            </Button>
          </div>
        </FlexBox>
      </Card>
    </>
  );
};

export default CreateCandidate;