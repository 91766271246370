/* eslint-disable import/no-unresolved */
import React from "react";
import { Button as AntButton } from "antd";

const Button = (props) => {
  const {
    type = "primary",
    disabled = false,
    danger = false,
    onClick,
    children,
  } = props;
  return (
    <AntButton
      type={type}
      disabled={disabled}
      danger={danger}
      onClick={onClick}
      {...props}
    >
      {children}
    </AntButton>
  );
};

export default Button;
