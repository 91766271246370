/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from "react"
import { Card, Typography } from "antd"
import AddInterviewForm from "components/AddInterviewForm"
import { useDispatch, useSelector } from "react-redux"
import { Admin } from "constants/Tables"
import { createTableColumns } from "utils/tableUtils"
import { useNavigate } from "react-router-dom"
import { PATH } from "constants"

const { Title } = Typography

const AddInterview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { jobs, candidates } = useSelector((state) => ({
    jobs: state?.JobModel.jobs,
    candidates: state?.CandidateModel.candidates,
  }));
  const [jobList, setJobList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const getColumns = useCallback(() => {
    const columns = Admin?.candidateList?.list;
    const newColumns = createTableColumns({ columns });
    setTableColumns(newColumns);
  });

  useEffect(() => {
    dispatch.JobModel.requestGetJobList();
    dispatch.CandidateModel.requestGetCandidateList();
    getColumns()
  }, [])

  useEffect(() => {
    if (jobs?.length > 0) {
      const filteredJobList = jobs.map(({ id, title }) => ({ id, title }));
      setJobList(filteredJobList);
    }
    if (candidates?.length > 0) {
      setCandidateList(candidates);
    }
  }, [jobs, candidates]);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
    setSelectedCandidates([]); // Reset selected candidates when job changes
  };

  const handleCandidateSelect = (selectedRowKeys, selectedRows) => {
    // You can perform additional logic here if needed
    setSelectedCandidates(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleCreateInterview = () => {
    const selectedCandidateIds = selectedCandidates.map(candidate => String(candidate.id));
    const payload  = {
      job:selectedJob,
      candidate_id:selectedCandidateIds
    }
    dispatch.InterviewModel.requestCreateInterview(payload)
    navigate(PATH.Interviews)
  };

  return (
    <Card>
      <Title level={4}>Add Interview</Title>
      <AddInterviewForm
        jobList={jobList}
        tableColumns={tableColumns}
        candidateList={candidateList}
        handleJobSelect={handleJobSelect}
        handleCandidateSelect={handleCandidateSelect}
        handleCreateInterview={handleCreateInterview}
        selectedJob={selectedJob}
        selectedCandidates={selectedCandidates}
        selectable={Admin.candidateList.selectable}
        selectedRowKeys={selectedRowKeys}
      />
    </Card>
  )
}

export default AddInterview