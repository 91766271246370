/* eslint-disable import/no-unresolved */
import React from "react"
import { Card, Typography } from "antd"
import AddJobForm from "components/AddJob"

const {Title} = Typography
const AddJob = () => {
  return (
    <Card>
      <Title level={4}>Add Job Profile</Title>
      <AddJobForm /> 
    </Card>
  )
}

export default AddJob
