/* eslint-disable import/no-unresolved */

export const PATH = {
  Root: "/",
  Dashboard: "/dashboard",
  Interviews: "/interviews",
  Jobroles: "/jobroles",
  Addjob: "/add-job",
  Login: "/login",
  Logout: "/logout",
  NotFound: "/not-found",
  JobDetails: "/job-details",
  AddInterview: "/add-interview",
  InterviewDetails: "/interview-details",
  Employees:"/employees",
  EmployeeDetails:"/employee-details",
  AddEmployee:"/add-employee",
  Companies:"/companies",
  AddCompany:"/add-company",
  CompanyDetails:"/company-details",
  Candidates:"/candidates",
  AddCandidate:"/add-candidate",
  CandidateDetails:"/candidate-details",
  Profile:"/profile"
}

export const STATUS_CODE =[ 200 ]
