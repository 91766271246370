/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { Flex } from "antd";
import JobProfileDetails from "components/JobProfileDetails";
import NumberOfQuestions from "components/NumberOfQuestions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const JobDetails = () => {
  const { jobDetails } = useSelector((state) => state?.JobModel);
  const { id } = useParams();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch.JobModel.requestGetJobDetails(id)
  }, [id]);

  return (
    <Flex gap="middle" vertical>
      <JobProfileDetails jobId={id} jobDetails={jobDetails}/>
      <NumberOfQuestions jobId={id} jobDetails={jobDetails}/>
    </Flex>
  )
}

export default JobDetails