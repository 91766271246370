/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { api } from "axiosRequest";
import { ADMIN_LOGIN_URL } from "constants/api";
import { setItemToLocalStorage } from "utils";

const model = {
  state: {
    login: {},
    loading: false,
    error: "",
  },
  reducers: {
    setLoginState: (state, payload) => {
      return {
        ...state,
        login: payload,
      };
    },
    setLoadingState: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setErrorState: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },

  effects: (dispatch) => ({
    async requestPostLogin(payload) {
      try {
        this.setLoadingState(true);
        const response = await api.post(ADMIN_LOGIN_URL,payload);
        this.setLoginState(response);
        setItemToLocalStorage("data", response);
        return response.json();
      } catch (err) {
        const { response, message } = err;
        const errorMsg = response?.data?.message
        this.setLoadingState(false);
        this.setErrorState(errorMsg);
        return err?.response?.data
      }
    },
  }),
};
export default model;
