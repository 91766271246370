/* eslint-disable import/no-unresolved */
import { StyledTitle } from "./styles";

const Typography = (props) => {
  const { level = 1, children, style } = props;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <StyledTitle level={level} style={style} {...props}>
      {children}
    </StyledTitle>
  );
};

export default Typography;
