/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from "react";
import { Descriptions, Col, Row, Typography, Card } from "antd";
import { getItemToLocalStorage, getCapitalizeFirstLetter } from "utils";

const { Title } = Typography;

const Profile = () => {
  const [profileData, setProfileData] = useState({});

  const formFields = [
    { key: "first_name", label: "First Name" },
    { key: "last_name", label: "Last Name" },
    { key: "email", label: "Email" },
    { key: "gender", label: "Gender" },
    { key: "profile_image", label: "Profile Image" },
    { key: "phone_number", label: "Phone Number" },
    { key: "active_status", label: "Status" },
  ];

  useEffect(() => {
    const userData = getItemToLocalStorage("data");
    if (userData) {
      setProfileData(userData.profile);
    }
  }, []);

  return (
    <Card>
      <Row gutter={16} justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
        <Col span={16}>
          <Title level={5}>Profile Details</Title>
        </Col>
      </Row>
      <Descriptions  layout="vertical" bordered>
        {formFields.map((field) => (
          <Descriptions.Item key={field.key} label={field.label}>
            {field.key === "active_status" ? (profileData[field.key] ? "Active" : "Inactive") : getCapitalizeFirstLetter(profileData[field.key])}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Card>
  );
};

export default Profile;




