/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Descriptions, Col, Row, Typography, Card } from "antd";
import CandidateResponseList from "components/CandidateResponse";
import { getCapitalizeFirstLetter } from "utils";

const { Text, Title } = Typography;

const InterviewDetails = () => {
  const dispatch = useDispatch();
  const { interviewDetails } = useSelector((state) => state?.InterviewModel);
  const { id } = useParams();

  useEffect(() => {
    dispatch.InterviewModel.requestGetInterviewDetails(id);
  }, [id]);

  const items = [
    { label: "ID", key: "id" },
    {
      label: "Candidate Name",
      key: "user_profile",
      render: () =>
        getCapitalizeFirstLetter(
          `${interviewDetails?.user_profile?.first_name} ${interviewDetails?.user_profile?.last_name}`
        ),
    },
    { label: "Candidate Email", key: "user_profile.email" },
    { label: "Job Title", key: "job.title" },
    { label: "Status", key: "interview_status" },
  ];

  return (
    <>
      <Row gutter={16} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Card>
            <Descriptions title={<Title level={5}>Interview Details</Title>} layout="vertical" bordered>
              {items.map((item) => (
                <Descriptions.Item key={item.label} label={item.label}>
                  {item.render ? item.render() : <Text>{interviewDetails?.[item.key]}</Text>}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Card>
            <CandidateResponseList candidateResponse={interviewDetails?.candidate_response} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InterviewDetails;
