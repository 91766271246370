/* eslint-disable import/no-unresolved */
import Image from "common/Image"
import Login from "components/Login"
import React from "react"
import landingImage from "../../assets/Images/talentberry-landing.svg"

const Auth = () => {
  return (
    <div style={{ display:"flex",justifyContent: "space-around ", height: "100vh",alignItems:"center" }}>
      <div style={{height:"90vh"}}>
        <Image src={landingImage} width="100%" height="100%" alt="Landing talentberry" />
      </div>
      <Login />
    </div>
  )
}

export default Auth