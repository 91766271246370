/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from "react";
import { Button, Card, Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "common/Modal";
import TextArea from "common/TextArea";
import { useDispatch } from "react-redux";
import ListQuestion from "./questions";
const { Title } = Typography;

const NumberOfQuestions = ({ jobId, jobDetails }) => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [questionSequence, setQuestionSequence] = useState(0);

  const handleAddQuestion = () => {
    setOpenModal(!openModal)
  }
  const handleCancelModal = () => {
    setOpenModal(!openModal)
    setCurrentQuestion("");
    setQuestionSequence((prevSequence) => prevSequence - 1);
  }

  const handleTextAreaChange = (e) => {
    setCurrentQuestion(e.target.value);
  };


  useEffect(() => {
    if (jobDetails) {
      setQuestionSequence(jobDetails?.last_qstn_sequence + 1);
    }
  }, [jobDetails]);


  const handleSubmitQuestion = () => {
    try {
      const payload = {
        question: currentQuestion,
        sequence: questionSequence,
        job: jobId
      }
      dispatch.QuestionModel.requestCreateQuestion(jobId, payload)

      setQuestionSequence((prevSequence) => prevSequence + 1);
    } catch (error) {
      console.error("Error adding question:", error);
    }

    setCurrentQuestion("");
    // setOpenModal(false);
  };
  return (
    <Card>
      <Flex justify='space-between' align='center'>
        <Title level={5}>Questions</Title>
        <Button type="primary" size="large" onClick={handleAddQuestion} icon={<PlusOutlined style={{ fontSize: "20px" }} />}>Add Question</Button>
      </Flex>
      <ListQuestion jobId={jobId} />
      {
        openModal &&
        <Modal
          title="Add Question "
          centered={true}
          okText="Add"
          cancelText={"Cancel"}
          open={openModal}
          closeIcon={true}
          onCancel={handleCancelModal}
          handleSubmit={handleSubmitQuestion}
        >
          <div>
            <TextArea rows={4} value={currentQuestion} onChange={handleTextAreaChange} />
          </div>
        </Modal>
      }
    </Card>
  )
}

export default NumberOfQuestions