
export const ADMIN_LOGIN_URL = `${process.env.REACT_APP__BASE_URL}/auth/admin-login`;
export const USER_REGISTER_URL = `${process.env.REACT_APP__BASE_URL}/auth/register`;
export const CREATE_COMPANY_URL = `${process.env.REACT_APP__BASE_URL}/api/company-create`;
export const LIST_COMPANY_URL = `${process.env.REACT_APP__BASE_URL}/api/company-list`;

export const COMPANY_DETAILS_URL = `${process.env.REACT_APP__BASE_URL}/api/company-detail`;
export const UPDATE_COMPANY_URL = `${process.env.REACT_APP__BASE_URL}/api/company-update`;
export const ACTIVATE_COMPANY_STATUS_URL = `${process.env.REACT_APP__BASE_URL}/api/company-status-change`;
export const CREATE_JOB_URL = `${process.env.REACT_APP__BASE_URL}/api/job-create`;
export const LIST_JOB_URL = `${process.env.REACT_APP__BASE_URL}/api/job-list`;
export const JOB_DETAILS_URL = `${process.env.REACT_APP__BASE_URL}/api/job-detail`;
export const JOB_UPDATE_URL = `${process.env.REACT_APP__BASE_URL}/api/job-update`;
export const ACTIVATE_JOB_STATUS_URL = `${process.env.REACT_APP__BASE_URL}/api/job-update`;
export const CREATE_QUESTION_URL = `${process.env.REACT_APP__BASE_URL}/api/question-create`;
export const LIST_QUESTION_URL = `${process.env.REACT_APP__BASE_URL}/api/question-list`;
export const QUESTION_DETAILS_URL = `${process.env.REACT_APP__BASE_URL}/api/question-detail`;
export const QUESTION_UPDATE_URL = `${process.env.REACT_APP__BASE_URL}/api/question-update`;
export const VERIFY_QUESTION_STATUS_URL = `${process.env.REACT_APP__BASE_URL}/api/question-verify-status-change`;
export const CREATE_CANDIDATE_URL = `${process.env.REACT_APP__BASE_URL}/api/candidate-create`;
export const CREATE_CANDIDATE_BULK = `${process.env.REACT_APP__BASE_URL}/api/candidate-bulk-create`;
export const LIST_CANDIDATE_URL = `${process.env.REACT_APP__BASE_URL}/api/candidate-list`;
export const CREATE_INTERVIEW_URL = `${process.env.REACT_APP__BASE_URL}/api/interview-create`;
export const LIST_INTERVIEW_URL = `${process.env.REACT_APP__BASE_URL}/api/interview-list`;
export const INTERVIEW_DETAILS_URL = `${process.env.REACT_APP__BASE_URL}/api/interview-detail`;
export const INTERVIEW_REPORT_GENERATION = `${process.env.REACT_APP__BASE_URL}/api/interview-process`;
export const INTERVIEW_REPORT_UPLOAD = `${process.env.REACT_APP__BASE_URL}/api/interview-report-upload`;
