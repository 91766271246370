import React from "react"
import { BarsOutlined } from "@ant-design/icons"
import { Flex, Typography } from "antd"

const { Text, Title } = Typography;

const DefaultQuestion = () => {
  return (
    <>
      <Flex justify='center' align='center' vertical>
        <BarsOutlined style={{fontSize:"60px",color:"black"}} />
        <Title level={3}>No Questions added yet to this Job Profile...</Title>
        <Text>Let us know if any assistance needed.</Text>
      </Flex>
    </>
  )
}

export default DefaultQuestion