/* eslint-disable import/no-unresolved */
import { Button, Form, Select, Typography } from "antd";
import CustomTable from "components/CustomTable";
import React from "react";

const { Title } = Typography;

const AddInterviewForm = ({
  jobList,
  candidateList,
  handleJobSelect,
  handleCandidateSelect,
  handleCreateInterview,
  tableColumns,
  selectable,
}) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" size="large">
      <Form.Item
        name="job-profile"
        label="Job Profile"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select a Job Profile"
          optionFilterProp="children"
          onChange={handleJobSelect}
        >
          {jobList?.map((job) => (
            <Select.Option key={job?.id} value={job?.id}>
              {job?.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Title level={4}>Candidate List</Title>
      <CustomTable
        columns={tableColumns}
        data={candidateList}
        onRowSelect={(selectedRows) => {
          const selectedRowKeys = selectedRows?.map(({row}) => row?.id);
          handleCandidateSelect(selectedRowKeys, selectedRows);
        }}
        selectable={selectable}
      />
      <Button type="primary" onClick={handleCreateInterview}>
        Create Interviews
      </Button>
    </Form>
  );
};

export default AddInterviewForm;
