/* eslint-disable import/no-unresolved */

import styled from "styled-components";

export const InterviewListWrapper = styled.div`
       display:flex;
       justify-content:space-between;
       margin-bottom:10px;
`

export const FileUploadWrapper = styled.div`
      display:flex;
      flex-direction:column;
      align-items:center;
      gap:10px;
      padding:4px;
      border:1px dotted gray;
      border-radius:5px;
`