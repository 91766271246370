/* eslint-disable import/no-unresolved */
import { SnackbarProvider } from "notistack";
import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import store from "./redux/store";
import AppRoutes from "./routes";


const MainComponent = () => {
  // const isLoading = useSelector(false);
  return (
    <Fragment >
      <AppRoutes />
      {/* Add Loading State here */}
    </Fragment>
  )
}

function App() {
  const persistor = getPersistor();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider autoHideDuration={3000} anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
          <MainComponent />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
