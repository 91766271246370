/* eslint-disable import/no-unresolved */

import React, { useState } from "react";
import {
  PieChartOutlined,
  TeamOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import Link from "antd/es/typography/Link";
import { PATH } from "constants";
import { isCompanyAdmin, isSuperAdmin } from "utils";
const SideBar = () => {
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false);

  const getItemsBasedOnRole = () => {
    if (isSuperAdmin()) {
      return [
        getItem("Dashboard", PATH.Dashboard, <PieChartOutlined />),
        getItem("Companies", PATH.Companies, <TeamOutlined />),
      ];
    }
    if (isCompanyAdmin()) {
      return [
        getItem("Dashboard", PATH.Dashboard, <PieChartOutlined />),
        getItem("Jobs", PATH.Jobroles, <SolutionOutlined />),
        getItem("Interviews", PATH.Interviews, <TeamOutlined />),
        getItem("Candidates", PATH.Candidates, <TeamOutlined />),
      ];
    }
    return [
      getItem("Home", PATH.Root, <PieChartOutlined />),
    ];
  };

  const items = getItemsBasedOnRole();


  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  return (
    <>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <Link href={PATH.Root}>
          <h6 style={{ color: "white", fontSize: "20px", textAlign: "center", cursor: "pointer" }}>Talent Berry</h6>
        </Link>
        <Menu
          onClick={({ key }) => {
            navigate(key)
          }}
          theme="dark"
          defaultSelectedKeys={[window.location.pathname]}
          mode="inline"
        >
          {items.map(item => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    </>
  )
}

export default React.memo(SideBar);
