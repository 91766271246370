import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Input, Space, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "constants";

const SubmitButton = ({ form }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submittable, setSubmittable] = useState(false);

  // Watch all values
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  const handleSubmit = () => {
    try {
      const payload = { title: values.title, job_description: values.description }
      dispatch?.JobModel?.requestCreateJob(payload);
      navigate(PATH.Jobroles);
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} onClick={handleSubmit}>
      Add Job
    </Button>
  );
};

const AddJobForm = () => {
  const [form] = Form.useForm();
  return (
    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" size='large'>
      <Form.Item
        name="title"
        label="Job Title"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Job Description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea rows={6} />
      </Form.Item>
      <Flex justify='space-between'>
        <Form.Item
        // name="ai-generated-question"
        // label="AI generated Question"
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton form={form} />

          </Space>
        </Form.Item>
      </Flex>
    </Form>
  );
};
export default AddJobForm;