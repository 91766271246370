import React from "react";
import styled from "styled-components";

const FileUploadContainer = styled.div`
  margin-bottom: 16px; 
`;

const FileInput = styled.input`
  display: none;
`;

const Label = styled.label`
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
`;

const FileUpload = ({ onFileUpload }) => {

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <FileUploadContainer>
      <Label>
        Choose File
        <FileInput type="file" onChange={handleFileChange} accept=".xlsx, .xls, .pdf, image/*" />
      </Label>
    </FileUploadContainer>
  );
};

export default FileUpload;
