/* eslint-disable import/no-unresolved */

import React from "react";
import { Modal as AntModal } from "antd";

const Modal = ({
  open,
  handleSubmit,
  children,
  footer,
  onCancel,
  maskClosable,
  title,
  centered,
  okText,
  cancelText,
  closeIcon,
  width

}) => {
  return (
    <AntModal
      title={title}
      centered={centered}
      okText={okText}
      cancelText={cancelText}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      footer={footer}
      maskClosable={maskClosable}
      closeIcon={closeIcon}
      width={width}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
