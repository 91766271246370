/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Space, Switch, Typography } from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import Input from "common/Input";
import { useDispatch } from "react-redux";

const { Text, Title } = Typography;

const JobProfileDetails = ({ jobId, jobDetails }) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setFormData(jobDetails);
  }, [jobDetails]);

  const switchStyle = {
    background: formData?.is_active ? "#52c41a" : "#f5222d", // Set background color based on status
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    const payload = {
      title: formData.title,
      job_description: formData.job_description,
      is_active: formData.is_active
    }
    dispatch?.JobModel?.requestUpdateJobDetails(jobId, payload)
    setIsEditing(false);
  };

  const handleCancel = () => {
    // Reset the form data to the original data when canceling
    setFormData(jobDetails);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return (
    <Card>
      <Row gutter={16} justify="space-between" align="middle">
        <Col span={16}>
          <Title level={5}>Job Profile Details</Title>
        </Col>
        <Col span={8}>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            {isEditing ? (
              <>
                <Button icon={<SaveOutlined />} onClick={handleSave}>
                  Save
                </Button>
                <Button icon={<CloseOutlined />} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button icon={<EditOutlined />} onClick={handleEdit}>
                Edit
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <Row gutter={16} justify="space-between" align="middle">
        <Col span={4}>
          <Text strong>ID : </Text>
          <Text type="secondary">{formData?.id}</Text>
        </Col>
        <Col span={6}>
          <Text strong>Title : </Text>
          {isEditing ? (
            <Input value={formData?.title} onChange={(e) => handleChange("title", e.target.value)} />
          ) : (
            <Text type="secondary">{formData?.title}</Text>
          )}
        </Col>
        <Col span={6}>
          <Text strong>Description : </Text>
          {isEditing ? (
            <Input value={formData?.job_description} onChange={(e) => handleChange("job_description", e.target.value)} />
          ) : (
            <Text type="secondary">{formData?.job_description}</Text>
          )}
        </Col>
        <Col span={4}>
          <Text strong>Number of Questions : </Text>
          <Text type="secondary">{jobDetails?.job_questions?.length}</Text>
        </Col>
        <Col span={4}>
          <Text strong>Status : </Text>
          {isEditing ? (
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={formData?.is_active}
              onChange={(value) => handleChange("is_active", value)}
              style={switchStyle}
            />
          ) : (
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" checked={formData?.is_active} disabled style={switchStyle} />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default JobProfileDetails;
